import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Card } from '../../src';
import SideSleeper from '../../src/Icons/SideSleeper';
import { Radio } from '../../src';
import '../../src/Card/card.module.scss';
import '../../src/Icons/sleepPositions.module.scss';
import '../../src/Radio/radio.module.scss';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "card"
    }}>{`Card`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Card from '@mfrm/mfcl/Card'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`The Card component can be used as a wrapper to contain elements and other components.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Card} mdxType="Props" />
    <h2 {...{
      "id": "card-1"
    }}>{`Card`}</h2>
    <Playground __position={1} __code={'<Card className=\"cardBackground\">\n  <SideSleeper className=\"sleepPosition\" />\n  <Radio className=\"radioBTNStyle\" label=\"King Size\" />\n</Card>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Card,
      SideSleeper,
      Radio,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Card className="cardBackground" mdxType="Card">
    <SideSleeper className="sleepPosition" mdxType="SideSleeper" />
    <Radio className="radioBTNStyle" label="King Size" mdxType="Radio" />
  </Card>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      